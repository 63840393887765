.p-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120%;

    .carousel-container {
        display: flex;
        overflow: hidden;
        // width: 70%;
        height: 100%;
        flex-direction: row;
        /* Adjust the width as needed */
    }

    .carousel-image {
        flex: 1;
        height: 140px;
        width: 100%;
        /* Adjust the height as needed */
        background-size: cover;
        background-position: center;
        width: 250px;
        transition: transform 0.3s;
        border-radius: 9.48px;
    }

    .carousel-image.center {
        transform: scale(1.2);
        min-width: 286px;
        height: 172px;
        /* Adjust the scale factor for the center image */
    }


    .next-button {
        right: 36px !important;
        color: #FAFAFA !important;
    }

    .prev-button {
        left: 36px !important;
        color: #FAFAFA !important;

    }
}