.image-stage {
  &-loaded {
    opacity: 1;
    animation: reveal 0.3s ease-in;
  }

  &-preview {
    filter: blur(0.5vw);
  }

  @keyframes reveal {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.image-stage-loaded {
  // to hide broken image icon
  text-indent: -10000px;
  border-radius: 5px;

}

.image-stage-loaded:-moz-broken {
  opacity: 0;
}

img.image-loader {
  object-fit: scale-down;
  // background-color: aqua;
}

.image-loaded {
  display: flex;
}

.image-loading {
  display: none;
}

.image-loader-display {
  display: flex;
}

.image-loader-hide {
  display: none;
}