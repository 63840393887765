.modal-custom {
  padding: 2.4rem !important;
  border-radius: 8px !important;
  .ant-modal-content {
    padding: 2.4rem !important;
    border-radius: 8px !important;
    .ant-modal-close {
      display: flex !important;
      width: 4rem;
      height: 4rem;
      justify-content: center !important;
      align-items: center !important;
      border-radius: 8px !important;
      background: #f6f7f8 !important;
      top: 2.4rem !important;
      right: 2.4rem !important;
      svg {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
    .ant-modal-header {
      padding: 0 !important;
      border-bottom: none !important;
    }
    .ant-modal-body {
      .delete-title {
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 160% */
        letter-spacing: -0.3px;
      }
      padding: 1rem 0 0 0 !important;
    }
    .ant-modal-footer {
      padding: 3rem 0 0 0 !important;
      border-top: none !important;
      display: flex;
      justify-content: space-between !important;
      button:nth-child(1) {
        display: flex;
        height: 4rem;
        padding: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1.5px solid #e0e3e5;
        gap: 10px;
        flex: 1 0 0;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
      }
      button:nth-child(2) {
        display: flex;
        height: 4rem;
        padding: 14px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #e91b16;
        gap: 10px;
        flex: 1 0 0;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
.profile-wrapper {
  .VLE-status-container {
    .table-div {
      .table-custom {
        .ant-table-tbody > tr.ant-table-row-selected > td {
          background: #fff !important;
        }
        .delete1 {
          .ant-tooltip {
            width: 265px;
          }
          .ant-tooltip-placement-bottom,
          .ant-tooltip-placement-bottomLeft,
          .ant-tooltip-placement-bottomRight {
            padding-top: 0 !important;
          }
          .ant-tooltip-placement-bottom .ant-tooltip-arrow,
          .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
          .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
            top: -13px;
            right: 5px;
          }
          .approval-icon {
            width: 2rem;
            height: 2rem;
          }
          &-highlight1 {
            width: 3rem;
            height: 3.2rem;
            border-radius: 8px;
            border: 1.5px solid #f8b3b1;
            background: #fde6e5;
            position: relative;
            text-align: center;
            line-height: 4.1rem;
          }
          &-highlight2 {
            width: 3rem;
            height: 3.2rem;
            border: 1.5px solid transparent;
            position: relative;
            text-align: center;
            line-height: 4.1rem;
          }
        }
        .restore1 {
          .ant-tooltip {
            width: 65px;
          }
          .ant-tooltip-placement-bottom,
          .ant-tooltip-placement-bottomLeft,
          .ant-tooltip-placement-bottomRight {
            padding-top: 0 !important;
          }
          .ant-tooltip-placement-bottom .ant-tooltip-arrow,
          .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
          .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
            top: -13px;
            right: 5px;
          }
          .approval-icon {
            width: 2rem;
            height: 2rem;
          }
          &-highlight1 {
            width: 3rem;
            height: 3.2rem;
            border-radius: 8px;
            border: 1.5px solid #e0e3e5;
            background: #eceef1;
            position: relative;
            text-align: center;
            line-height: 4.1rem;
          }
          &-highlight2 {
            width: 3rem;
            height: 3.2rem;
            border: 1.5px solid transparent;
            position: relative;
            text-align: center;
            line-height: 4.1rem;
          }
        }
      }
    }
    .right-group {
      display: flex;
      gap: 2.9rem;
    }
    .search {
      border: 0.1rem solid #e0e3e5;
      box-sizing: border-box;
      box-shadow: 0rem 0rem 1.8rem rgba(30, 21, 83, 0.01);
      border-radius: 6px;
      width: 41rem;
      padding: 1.2rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      color: #152370;
      // &:active,
      // &:focus,
      // &:hover {
      //   background: #eff1fc;
      // }
      .ant-input-suffix {
        cursor: pointer;
      }
    }
    .batch-delete-button {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 18px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid #e0e3e5;
      background: #fff;
      /* Elevation/2 */
      box-shadow: 0px 32px 48px -16px rgba(0, 0, 0, 0.1);
      .reset-icon {
        height: 1.8em;
        width: 1.8rem;
        margin-left: 0.8rem;
      }
      .batch-action-icon {
        width: 3rem;
        height: 2rem;
        border-radius: 8px;
        position: relative;
        text-align: center;
        line-height: 2.5rem;
        .ant-tooltip-placement-bottom,
        .ant-tooltip-placement-bottomLeft,
        .ant-tooltip-placement-bottomRight {
          padding-top: 0 !important;
        }
        .ant-tooltip-placement-bottom .ant-tooltip-arrow,
        .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
        .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
          top: -13px;
          right: 5px;
        }
        .approval-icon {
          width: 1.6rem;
          height: 1.6rem;
        }
        &-restore3 {
          .ant-tooltip {
            width: 65px;
          }
        }
        &-delete3 {
          .ant-tooltip {
            width: 265px;
          }
        }
      }
      span {
        color: #5c6674;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.popover {
  .ant-popover-inner {
    border-radius: 8px !important;
    border: 0.5px solid #eceef1 !important;
    box-shadow: 0px 10px 24px 0px rgba(35, 40, 60, 0.05) !important;
  }
  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    padding-top: 3px;
  }
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner-content {
    padding: 0.4rem !important;
  }

  .styled-date {
    display: flex;
    .date-form {
      display: inline-flex;
      flex-direction: column;
      // justify-content: space-between;
      // margin: 2.4rem;
      padding: 0.4rem;
      background: #fcfcff;
      gap: 0.6rem;
      width: 15rem;
      border-radius: 10px;
      background: #e3eeff;
      .calnder {
        cursor: pointer;
        &-first {
          display: flex;
          padding: 1rem 1.6rem;
          align-items: center;
          border-radius: 8px;
          background: #fff;
          color: #a149ef;
          /* Body/Small/Medium */
          font-family: Inter;
          font-size: 1.2rem;
          font-style: normal;
          letter-spacing: 0.12px;
        }
        &-nth {
          display: flex;
          width: 14rem;
          padding: 1rem 1.6rem;
          align-items: center;
          font-family: Inter;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2rem; /* 166.667% */
          letter-spacing: 0.12px;
          color: #5c6674 !important;
        }
      }
    }
  }
  .calender-column {
    padding: 1rem 0.8rem 0 0;
    .footer-calender {
      margin-top: 2rem;
      padding: 1.2rem 0.6rem;
      border-top: 1px solid #eceef1;
      display: flex;
      justify-content: space-between;
      .footer-left-content {
        width: fit-content;
        .input-content {
          width: 18.5rem !important;
          display: inline !important;
          border-radius: 6px !important;
          border: 1px solid #e0e3e5 !important;
          background: #fff !important;
          height: 4rem !important;
          font-family: Inter !important;
          font-size: 1.4rem !important;
          font-style: normal !important;
          color: #5c6674 !important;
        }
      }
      .footer-right-content {
        display: flex;
        color: #394455;
        text-align: center;
        /* Body/Default/Bold */
        font-family: Inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.175px;
        .cancel-button {
          cursor: pointer;
          background-color: #fff;
          border-radius: 0.4rem;
          border: none;
          align-items: center;
          justify-content: center;
          display: flex;
          font-weight: 600 !important;
          height: 4rem;
          padding: 1.4rem 1.6rem;
          gap: 10px;
        }
        .apply-button {
          cursor: pointer;
          font-weight: 500;
          background-color: #7335cb;
          border-radius: 0.4rem;
          color: #fff;
          border: none;
          align-items: center;
          justify-content: center;
          display: flex;
          height: 4rem;
          padding: 1.4rem 1.6rem;
          gap: 10px;
        }
      }
    }

    .calender-css {
      display: inline-block;
      margin-left: 2rem;
      border: none !important;
      .react-calendar__navigation button:enabled:hover {
        background: #f2e5fd !important;
      }
      &.react-calendar--selectRange .react-calendar__tile--hover {
        background: #f2e5fd !important;
      }

      &.react-calendar__navigation__label {
        background: none !important;
      }
      & .react-calendar__tile {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
        font-family: Inter;
        letter-spacing: 0.14px;
        // height: 4rem !important;
        // width: 4rem !important;
        // border: 1px solid #eff1fc;
        // height: 4rem;
      }
      &.react-calendar--doubleView {
        width: 65rem;
      }
      &.react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background: #f2e5fd !important;
      }
      &.react-calendar__navigation button:disabled {
        background: #f2e5fd !important;
      }

      .react-calendar__tile:hover {
        background: #f2e5fd !important;
      }

      & .react-calendar__navigation__label {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: 600;
        font-size: 1.6rem;
        color: #2e3044;
        letter-spacing: -0.5px;
        &:disabled {
          background: #f2e5fd !important;
        }
        // background: none !important;
      }

      & .react-calendar__navigation__label__divider {
        visibility: hidden;
      }
      & .react-calendar__month-view__days__day--neighboringMonth {
        visibility: hidden;
      }
      & .react-calendar__tile--now {
        background: none;
        border: solid 1px #7335cb;
      }
      & .react-calendar__month-view__weekdays__weekday {
        font-size: 1.2rem;
        font-weight: 600;
        color: #5c6674;
        font-family: Inter;
        letter-spacing: 0.175px;
        text-transform: capitalize;

        & abbr {
          text-decoration: none;
        }
        & abbr[title] {
          cursor: default;
        }
      }

      & .react-calendar__tile--active,
      & .react-calendar__tile--hasActive {
        &.react-calendar__tile--rangeEnd,
        &.react-calendar__tile--rangeStart {
          color: #fff !important;

          background: #8615ea !important;
          // border-radius: 50% !important;
        }
        background: #f2e5fd !important;
        // border-radius: 50% !important;
      }
    }
  }
}