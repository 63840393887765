.aiquire-create-permission-container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
}

.temp {
    position: absolute;
    top: -6.2rem;
    right: -6.0rem;
    transform: scale(.3);
    z-index: 11;
    pointer-events: none;
}