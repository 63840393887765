@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "utilities/theme/colors.css";
@import "../src/assets/scss/index.scss";
@font-face {
  font-family: "Roca";
  src: url("./assets/fonts/RocaOne-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/RocaOne-Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/RocaOne-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/RocaOne-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
}

* {
  font-family: "Inter", sans-serif;
}
html {
  height: auto;
  font-size: 10px;
}
body {
  touch-action: none;
}

//reset css
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  height: auto !important;
  font-size: 10px;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

//tooltip start
.tooltip-primary,
.tooltip-secondary {
  position: relative;
}
.tooltip-primary:before,
.tooltip-secondary:before {
  content: attr(data-tip);
  font-family: "Inter";
  position: absolute;
  padding: 0.6rem 0.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 0.4rem;
  line-height: 1.6rem;
  text-align: center;
  text-transform: none;
  z-index: 99;
}

.tooltip-primary:before {
  max-width: 13rem;
  padding: 0.6rem 0.8rem;
  color: #ffffff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0.2rem;
  box-shadow: 0 0.3rem 0.6rem -0.4rem rgb(0 0 0 / 12%),
    0 0.6rem 1.6rem 0 rgb(0 0 0 / 8%), 0 0.9rem 2.8rem 0.8rem rgb(0 0 0 / 5%);
  width: fit-content;
}
.tooltip-secondary:before {
  max-width: 8rem;
  padding: 0.6rem 0.8rem;
  color: rgba(0, 0, 0, 0.75);
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #ffffff;
  border-radius: 0.2rem;
  box-shadow: 0 0.3rem 0.6rem -0.4rem rgb(0 0 0 / 12%),
    0 0.6rem 1.6rem 0 rgb(0 0 0 / 8%), 0 0.9rem 2.8rem 0.8rem rgb(0 0 0 / 5%);
  width: fit-content;
}

// Top
.tooltip-primary.top:before,
.tooltip-secondary.top:before {
  top: initial;
  margin: initial;
  bottom: 100%;
  margin-bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-primary.top:after,
.tooltip-secondary.top:after {
  content: "";
  position: absolute;
  bottom: 100%;
  margin-bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-primary.top:after {
  border: 0.5rem solid rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}
.tooltip-secondary.top:after {
  border: 0.5rem solid #ffffff;
  border-color: #ffffff transparent transparent transparent;
}

// Right
.tooltip-primary.right:before,
.tooltip-secondary.right:before {
  left: 100%;
  margin: initial;
  right: initial;
  margin-left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-primary.right:after,
.tooltip-secondary.right:after {
  content: "";
  position: absolute;
  left: 100%;
  margin-left: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-primary.right:after {
  border: 0.5rem solid rgba(0, 0, 0, 0.75);
  border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
}
.tooltip-secondary.right:after {
  border: 0.5rem solid #ffffff;
  border-color: transparent #ffffff transparent transparent;
}

// Bottom
.tooltip-primary.bottom:before,
.tooltip-secondary.bottom:before {
  bottom: initial;
  margin: initial;
  top: 100%;
  margin-top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-primary.bottom:after,
.tooltip-secondary.bottom:after {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-primary.bottom:after {
  border: 0.5rem solid rgba(0, 0, 0, 0.75);
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}
.tooltip-secondary.bottom:after {
  border: 0.5rem solid #ffffff;
  border-color: transparent transparent #ffffff transparent;
}

// Left
.tooltip-primary.left:before,
.tooltip-secondary.left:before {
  left: initial;
  margin: initial;
  right: 100%;
  margin-right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-primary.left:after,
.tooltip-secondary.left:after {
  content: "";
  position: absolute;
  right: 100%;
  margin-right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-primary.left:after {
  border: 0.5rem solid rgba(0, 0, 0, 0.75);
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.75);
}
.tooltip-secondary.left:after {
  border: 0.5rem solid #ffffff;
  border-color: transparent transparent transparent #ffffff;
}

.tooltip-primary:before,
.tooltip-primary:after,
.tooltip-secondary:before,
.tooltip-secondary:after {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  z-index: 99;
}
.tooltip-primary:hover:before,
.tooltip-primary:hover:after,
.tooltip-secondary:hover:before,
.tooltip-secondary:hover:after {
  opacity: 1;
  visibility: visible;
}
//tooltip end

//navbar
.site-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  z-index: 111;
  background: #fff;
}
header.ant-layout-header.site-header {
  height: $nav-header;
  line-height: $nav-header;
}
.agency-files-header {
  height: 6.4rem;
  line-height: 6.4rem;
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  background-color: #fff;
  border-bottom: 2px solid #e0e3e5;
  .agency-back-icon {
    width: 3rem !important;
    height: 6rem !important;
    cursor: pointer;
  }
  .header-title {
    padding-left: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
  }
}

// Toast override
.Toastify {
  z-index: 999999;
  font-size: 1.6rem;
  &__toast {
    min-height: unset;
    padding: 1.5rem;
    border-radius: 1rem;
    &-body {
      word-break: break-all;
    }
    &-container {
      width: auto;
      min-width: fit-content;
      max-width: 100rem;
    }
  }
}

//permissions not allowed
.permission-denied-lock {
  position: relative;
  opacity: 0.5;
  &::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f023";
    width: fit-content;
    margin-left: auto;
    padding-left: 1rem;
    font-size: 1.6rem;
  }
}
.permission-denied {
  opacity: 0.5;
}

.loader-wrap-asset {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  & img {
    height: 5rem;
    width: auto;
  }
}
.canvas-container {
  margin: 0rem auto;
}

//skeleton loader
.pulsate {
  // background: linear-gradient(-45deg,  #DDDDDD, #F0F0F0, #DDDDDD, #F0F0F0);
  background: #ffffff;
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

//Download and hyper personalize projects popup
.action-popups-container {
  bottom: 2.2rem;
  position: fixed;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  &.left-side {
    left: 3rem;
    bottom: 0;
  }
  &.right-side {
    right: 0rem;
    bottom: 0rem;
    z-index: 2147483002;
    box-shadow: 0rem 0rem 1.8rem rgba(30, 21, 83, 0.1);
  }
}

//no selection allowed
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

//So that Drawer appears on top of chat bot so that it does not cover action buttons
// .ant-drawer {
//   z-index: 9999999999;
// }

// Overiding the default width provided by ant design
// .ant-drawer-content-wrapper {
//   width: 69.2rem !important;
//   font-family: "Inter", sans-serif;
// }

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 1rem;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
  background: transparent;
}
::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

@media only screen and (max-width: 3072px) {
  html {
    font-size: 8.96px;
  }
}
@media only screen and (max-width: 2880px) {
  html {
    font-size: 9.28px;
  }
}
@media only screen and (max-width: 2560px) {
  html {
    font-size: 9.6px;
  }
}
@media only screen and (max-width: 2304px) {
  html {
    font-size: 9.6px;
  }
}
@media only screen and (max-width: 1920px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1680px) {
  html {
    font-size: 9px;
  }
}
@media only screen and (max-width: 1440px) {
  html {
    font-size: 9px;
  }
}
@media only screen and (max-width: 1280px) {
  html {
    font-size: 8px;
  }
}
