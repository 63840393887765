@import "src/assets/scss/index.scss";

.popup-overlay {
  position: fixed !important;
}
.hyper-personalize {
  &-wrapper {
    margin-top: #{$menu-height};
  }

  &-head {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
  }

  &-body {
    background: #f0f0f0;

    &-left {
      position: fixed;
      width: 40rem;
      height: calc(100vh - #{$menu-height});
      max-height: calc(100vh - #{$menu-height});
      align-self: flex-start;
      border-right: 0.1rem solid #202020;
      border-bottom: 0.1rem solid #202020;
    }
    &-right {
      display: flex;
      position: fixed;
      width: 35rem;
    }
    &-center {
      height: calc(100vh - #{$menu-height});
      overflow: auto;
      padding: 0rem 35rem 0rem 40rem;
      display: grid;
      justify-content: center;
      align-content: center;
    }
  }
}

.layer-menu-hyper {
  &__discarded {
    position: absolute;
    height: 100vh;
    width: calc(100vw - 73rem); // width - menu widths
    top: 0rem;
    left: 40rem; // left tary width
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    & > p {
      font-size: 1.6rem;
    }
  }
  &-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - #{$menu-height});
    max-height: calc(100vh - #{$menu-height});
    overflow: auto;
    &__switch {
      position: fixed;
      z-index: 10;
      top: 13.6rem;
      left: 40rem;
      padding: 0.5rem 2rem;
      &__updating {
        color: #3f51b5;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        font-weight: bold;
      }
      &:hover {
        .single-mode-msg {
          display: block;
        }
      }
      .single-mode-msg {
        display: none;
        position: absolute;
        top: 4rem;
        left: 1.5rem;
        border-radius: 0.3rem;
        background-color: #202020;
        color: white;
        padding: 0.8rem;
        font-size: 1.2rem;
        word-wrap: break-word;
        text-align: justify;
      }
    }
    &__button {
      position: fixed;
      z-index: 18;
      top: 9.8rem;
      right: 37rem;
      padding: 0.8rem 1.5rem;
      box-sizing: border-box;
      border-radius: 0.4rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 150%;
      letter-spacing: 0.02rem;
      cursor: pointer;
      outline: none;
      border: none;
      margin-left: 1rem;
      background-color: #20007f;
      color: #fff;
      &:hover {
        background-color: #0c0061;
      }
      &:disabled {
        background-color: #929292;
      }
    }
  }
  &-singleLayer {
    display: flex;
    flex-direction: column;
    &__add {
      width: 10rem;
      padding: 0rem;
      border-radius: 0.4rem;
      text-transform: uppercase;
      margin: -0.5rem auto 1rem auto;
      background: #20007f;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #20007f;
      color: white;
      & svg {
        width: 1rem;
        margin-right: 1rem;
        fill: white;
      }
    }
    &__tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      border-top: 0.1rem solid #202020;
      & svg {
        margin: 1rem 0rem 1rem 1rem;
        height: 2.4rem;
        cursor: pointer;
      }
      & span {
        flex: 1;
        cursor: pointer;
        color: #202020;
        line-height: 4rem;
        padding-left: 2rem;
      }
      &__edit {
        padding-right: 1rem;
        cursor: pointer;
        & svg {
          fill: #17005c;
        }
      }
      .white {
        & svg {
          fill: white;
        }
      }
    }
    &-objects {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1rem;
      // justify-content: space-around;
      // border-top: 0.1rem solid #202020;
      &__single {
        position: relative;
        &__original {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          & > span {
            background: yellowgreen;
            color: white;
            margin: 0;
            padding: 0.3rem 0.5rem;
            font-size: 1rem;
            font-family: "Montserrat-ExtraBold", sans-serif;
            border-radius: 0.5rem;
            z-index: 12;
            letter-spacing: 0.1rem;
          }
        }
        & .view-btn {
          position: absolute;
          display: none;
          top: 0.3rem;
          left: 0.5rem;
          opacity: 0.9;
          z-index: 5;
          padding: 0.3rem;
          font-size: 1.4rem;
          cursor: pointer;
          transition: all 0.1s ease-in;
          height: 2.5rem;
          width: auto;
          &:hover {
            transform: scale(1.2);
            opacity: 1;
          }
        }
        & .delete-btn {
          position: absolute;
          display: none;
          bottom: 1rem;
          right: 1rem;
          opacity: 0.9;
          z-index: 5;
          padding: 0.3rem;
          font-size: 1.4rem;
          cursor: pointer;
          transition: all 0.1s ease-in;
          height: 2rem;
          width: auto;
          & svg {
            height: 1.5rem;
            width: auto;
            fill: red;
          }
          &:hover {
            transform: scale(1.2);
            opacity: 1;
          }
        }
        &:hover {
          .view-btn {
            display: block;
          }
          .delete-btn {
            display: block;
          }
        }
        & img {
          width: auto;
          margin: 0.5rem;
          cursor: pointer;
        }
        &__img {
          height: 8rem;
          width: 8rem !important;
          border: 0.1rem solid #ededed;
          object-fit: contain;
          background: white;
        }
        & p {
          margin: 0.5rem 0.7rem;
          background-color: white;
          color: black;
          border-radius: 0.5rem;
          border: 0.1rem solid #ededed;
          padding: 0.7rem 3.5rem;
          cursor: pointer;
          font-size: 1rem;
          word-break: break-word;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10rem;
          min-height: 2.5rem;
          text-align: center;
          box-sizing: content-box;
        }
        & .tick {
          position: absolute;
          top: 0.8rem;
          right: 0.8rem;
          display: none;
          height: 2.5rem;
          width: auto;
        }
      }
    }
  }
}
