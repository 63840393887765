.job-approval-wrapper {
  width: 100%;
  max-width: 160rem;
  .m-t0 {
    margin-top: 0;
  }
  .m-t3 {
    margin-top: 0.3rem;
  }
  .text-color-blue {
    color: #20007f !important;
  }
  * {
    box-sizing: border-box;
  }
  .job-approval-row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -2.5rem;
    padding: 2rem 3rem;
    padding-bottom: 10rem;
    .job-approval-col {
      flex: 0 0 50%;
      max-width: 50%;
      box-sizing: border-box;
      padding: 0 2.5rem;
    }
  }
  .job-approval-white-wrapper {
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 2.5rem 3rem;
    height: calc(100% - 5rem);
    margin-bottom: 5rem;

    .approval-common-head {
      h6 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 0.9;
        color: #333333;
        margin: 0;
      }
      p {
        font-size: 1.4rem;
        line-height: 1.29;
        letter-spacing: -0.007rem;
        color: #707070;
        margin: 0.6rem 0 0;
      }
    }
    .approval-common-body {
      border-left: 0.1rem solid #e8e8e8;
      padding-left: 3rem;
      padding-bottom: 3rem;
      margin-bottom: 1rem;
    }
  }

  .approval-status-bar {
    position: relative;
    border-bottom: 0.1rem solid #e5e5e5;
    .approval-description-wrapper {
      display: flex;
      width: 100%;
      display: flex;
      margin-top: 3rem;

      .approval-left-description {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .approval-right-description {
        flex: 0 0 30%;
        max-width: 30%;
        .modified-date {
          display: block;
          text-align: right;
          span.modified-text {
            display: block;
            font-size: 1.2rem;
            letter-spacing: -0.006rem;
            color: #707070;
          }
        }
      }
    }
  }

  .profile-wrapper {
    padding-bottom: 3rem;
    .prfl-detail {
      display: flex;
      align-items: center;
      .prfl-img-outer {
        display: inline-block;
        margin-right: 0.5rem;
        img {
          width: 2.3rem;
          height: 2.3rem;
          border-radius: 2.3rem;
          border: solid 0.3rem #707070;
          object-fit: cover;
        }
      }
      p {
        margin: 0;
        font-size: 1.4rem;
        color: #333333e3;
      }
    }
    .prfl-description {
      font-size: 1.4rem;
      color: #333333e3;
      margin: 0.5rem 0 0;
    }
  }

  .proress-circle {
    &:before {
      width: 1rem;
      height: 1rem;
      border: solid 0.4rem #e4e4e4;
      background-color: #a2a2a2;
      content: "";
      border-radius: 50%;
      display: block;
      position: absolute;
      left: -4rem;
      z-index: 99;
    }
    &.danger {
      &:before {
        background-color: #f13232;
      }
    }
    &.primary {
      &:before {
        background-color: #4c71f0;
      }
    }
    &.warning {
      &:before {
        background-color: #f19e32;
      }
    }
    &.sucess {
      &:before {
        background-color: #3cd46f;
      }
    }
  }
}
