:root {
  --default-color: #7335cb;
  --secondary-color: #12c8b2;
  --primary-color: #152370;
  --slategrey: slategrey;
  --bg-color: #fcfcff;
  --interface-blue20: #f7f8fe;
  --card-border: #cfd5f6;
  --interface-blue: #4253a9;
  --light-blue: #8090e7;
  --hover-color: #986bd9;
  --delete-color: #fa4300;
  --interface-blue40: #eff1fc;
  --warning-yellow: #fbbc1a;
}
