$expand-wrapper-width: 10.5rem;
$shirnk-wrapper-width: 37rem;
$menu-height: 13.6rem;
$option-wrapper-height: 10.5rem;
$stage-container-width: 25rem;
$stage-container-height: 40rem;

$nav-header: 6.4rem;
$search-bar: 6.4rem;
$left-sidebar: 26.4rem;
$top-filter: 5.6rem;
$project-view-zoom-bar: 6.4rem;
$project-view-counter: 9.6rem;
$project-view-footer: 5.6rem;
$project-view-activity-bar: 37.2rem;
$project-view-slider: 7.2rem;
