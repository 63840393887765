#images {
    margin: 0 auto;
    display: flex;
    height: inherit;
    width: auto;
    padding: 0rem;
    position: relative;
    z-index: 1;
    .annotate-mode {
        cursor: url('../../assets/img/pin.cur'), default;
    }
    .image-stage {
        &-loaded {
            opacity: 1;
            animation: reveal 0.3s ease-in;
        }
        &-preview {
            filter: blur(0.5vw);
        }
        @keyframes reveal {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .image-stage-loaded {
        text-indent: -10000px;
    }
    .image-stage-loaded:-moz-broken {
        opacity: 0;
    }
    img.image-loader {
        object-fit: scale-down;
    }
    .image-loaded {
        display: flex;
    }
    .image-loading {
        display: none;
    }
    .image-loader-display {
        display: flex;
    }
    .image-loader-hide {
        display: none;
    }
}  