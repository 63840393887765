.VLE-status-container {
  width: 100vw;
  padding: 0 100px;
  margin-top: 100px;

  .header {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 50px 0;

    .header-title {
      color: #18236c;

      /* Body/Large/Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      letter-spacing: -0.5px;
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }
  }

  .sub-header {
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
    .left-group {
      display: flex;
      gap: 2.9rem;
      .date-picker-container {
        height: 40px;
        display: flex;
        svg {
          height: 16px;
          width: 16px;
        }
        span {
          color: #394455;
          text-align: center;
          /* Body/Default/Bold */
          font-family: Inter;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 600;
          /* 157.143% */
          letter-spacing: 0.175px;
          margin-left: 0.8rem;
        }
        .date-picker-child1 {
          border: 1.5px solid #e0e3e5 !important;
          border-right-color: transparent !important;
          border-radius: 8px 0px 0px 8px !important;
          height: 40px;
          padding: 16px;
          display: flex;
          align-items: center;
        }
        .date-picker-child2 {
          border-radius: 0px 8px 8px 0px !important;
          border: 1.5px solid #e0e3e5 !important;
          height: 40px;
          padding: 1.2rem 0.8rem;
        }
      }
      .popver-input {
        width: 14rem !important;
        border-radius: 8px 10px 10px 8px !important;
        border: 1.5px solid #e0e3e5 !important;
        border-right-color: transparent !important;
        background: #fff !important;
        padding: 0 0 0 0.6rem !important;
        .ant-input-suffix {
          .anticon-close-circle {
            display: none;
          }
          svg {
            height: 4.7rem;
            width: auto;
          }
        }
        .ant-input {
          background: #fff !important;
        }
      }
    }
    .download-csv-btn {
      display: inline-flex;
      height: 40px;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;

      border-radius: 4px;
      border: 1.5px solid var(--Purple, #6b38c4);
      color: var(--Purple, #6b38c4);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 166.667% */
      letter-spacing: 0.12px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    height: 50vh;
    overflow: scroll;
    padding: 20px;
  }

  .list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .img-folder {
      margin-right: 2.2rem;
    }
    svg {
      height: 2.4rem;
      width: 2.4rem;
      display: flex;
      cursor: pointer;
    }
    .folder-name {
      display: flex;
      align-items: center;
      color: var(--Color-Font-Dark-80, #585969);

      /* Body/Default/Bold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      /* 157.143% */
      letter-spacing: 0.175px;
    }
  }

  .right {
    display: flex;
    gap: 25px;
  }

  .date {
    color: var(--Color-Font-Dark-50, #9798a1);
    display: flex;
    align-items: center;
    /* Body/Small/Normal */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.12px;
  }

  .view-btn {
    display: flex;
    width: 89px;
    height: 40px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1.5px solid var(--Purple, #6b38c4);
    color: var(--Purple, #6b38c4);
    /* Body/Small/Normal */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.12px;
  }
}