.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.carousel {
    overflow: hidden;
    width: 80%;
    /* Adjust as needed */
    max-width: 800px;
    /* Adjust as needed */
    position: relative;
}

.carousel-track {
    display: flex;
    gap: 20px;
    transition: transform 0.3s ease-in-out;
}

.carousel-item {
    flex: 0 0 30.3333%;
    /* Each item takes up one-third of the container */
}

.carousel-item img {
    width: 100%;
    height: auto;
}

.prev-button,
.next-button {
    position: absolute;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    background-color: white;
    border: 1px solid #EBEDF0;
    border-radius: 50%;
    /* filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.03)); */

}

.prev-button {
    float: left;
    left: 72px;
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-button {
    float: right;
    right: 72px;
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo-carousel-container {
    display: flex;
}